import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Dialog, DialogBody, DialogSurface, DialogTitle, Switch, TableCellLayout, Tooltip, createTableColumn, } from "@fluentui/react-components";
import { EditRegular, AlertUrgentRegular } from "@fluentui/react-icons";
import EditPolicyModal from "./modals/EditPolicyItemModal";
import PolicyUserInfoModal from "./modals/PolicyUserInfoModal";
import SendReminderModalBody from "./modals/SendReminderModalBody";
import moment from "moment";
const PolicyUserInfoWrapper = styled.div.withConfig({ displayName: "PolicyUserInfoWrapper", componentId: "-1hcwwqt" }) ``;
const PolicyUserInfoButton = styled(Button).withConfig({ displayName: "PolicyUserInfoButton", componentId: "-1vskj3v" }) `
  color: #512da8 !important;
  font-weight: 400 !important;
  padding-left: 0px !important;
  justify-content: left !important;
`;
const ActionsTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "ActionsTableCellLayout", componentId: "-6ap8br" }) `
  justify-content: flex-end;
`;
const StyledDataGrid = styled(DataGrid).withConfig({ displayName: "StyledDataGrid", componentId: "-y2hgs" }) `
  padding-left: 0;
`;
const DataGridRowWrapper = styled.div.withConfig({ displayName: "DataGridRowWrapper", componentId: "-j8o2oq" }) `
  position: relative;
  width: 100%;
`;
const TruncatedSpan = styled.span.withConfig({ displayName: "TruncatedSpan", componentId: "-1ofo9oq" }) `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const StyledSpan = styled.span.withConfig({ displayName: "StyledSpan", componentId: "-1vavqzd" }) `
  padding-left: 20px;
`;
const PolicyItemList = ({ policySource, onMandatoryPolicyItemChange, onEnabledAssistantChange, onSave, }) => {
    const [modalPolicyData, setModalPolicyData] = useState({});
    const [toggleClicked, setToggleClicked] = useState(false);
    const { items } = policySource;
    const formatDate = (date) => moment(Date.parse(date)).format("DD/MM/YYYY");
    const columns = [
        createTableColumn({
            columnId: "name",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("b", { children: "Policy" }) })),
            renderCell: (item) => (_jsx(Tooltip, Object.assign({ appearance: "inverted", content: item === null || item === void 0 ? void 0 : item.title, positioning: "after-bottom", relationship: "description", withArrow: true }, { children: _jsx(TruncatedSpan, { children: item === null || item === void 0 ? void 0 : item.title }) }))),
        }),
        createTableColumn({
            columnId: "mandatory",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("b", { children: "Mandatory" }) })),
            renderCell: (item) => (_jsx(TableCellLayout, { children: _jsx(Switch, { checked: item === null || item === void 0 ? void 0 : item.mandatory, onClick: (e) => {
                        e.stopPropagation();
                        onMandatoryPolicyItemChange(item.id);
                    } }) })),
        }),
        createTableColumn({
            columnId: "readNr",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("b", { children: "Users who read it" }) })),
            renderCell: (item) => {
                var _a, _b, _c, _d;
                return (_jsx(TableCellLayout, { children: (item === null || item === void 0 ? void 0 : item.mandatory) ? (_jsxs(PolicyUserInfoWrapper, { children: [_jsxs(PolicyUserInfoButton, Object.assign({ appearance: "transparent", onClick: (e) => handleModalOpen(e, item, "userInfo") }, { children: [_jsx("b", { children: (_b = (_a = item === null || item === void 0 ? void 0 : item.readConfirmations) === null || _a === void 0 ? void 0 : _a.length) !== null && _b !== void 0 ? _b : 0 }), "/", (_d = ((_c = item === null || item === void 0 ? void 0 : item.readConfirmations) === null || _c === void 0 ? void 0 : _c.length) + (item === null || item === void 0 ? void 0 : item.unconfirmedUsers)) !== null && _d !== void 0 ? _d : 0] })), item.unconfirmedUsers ? (_jsx(Button, { appearance: "transparent", icon: _jsx(AlertUrgentRegular, { color: "#512DA8" }), onClick: (e) => handleModalOpen(e, item, "reminder") })) : null] })) : ("—") }));
            },
        }),
        createTableColumn({
            columnId: "dueDate",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx(StyledSpan, { children: _jsx("b", { children: "Due date" }) }) })),
            renderCell: (item) => (_jsx(StyledSpan, { children: (item === null || item === void 0 ? void 0 : item.dueDate) ? formatDate(item === null || item === void 0 ? void 0 : item.dueDate) : "—" })),
        }),
        createTableColumn({
            columnId: "enableassistant",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("b", { children: "Enable Assistant" }) })),
            renderCell: (item) => (_jsx(TableCellLayout, { children: _jsx(Switch, { checked: item === null || item === void 0 ? void 0 : item.enabledAssistant, onClick: (e) => {
                        e.stopPropagation();
                        setToggleClicked(true);
                        onEnabledAssistantChange(item.id);
                    }, disabled: !item.absoluteUrl.endsWith(".aspx") || toggleClicked }) })),
        }),
        createTableColumn({
            columnId: "actions",
            renderHeaderCell: () => "",
            renderCell: (item) => (_jsx(ActionsTableCellLayout, { children: _jsx(Button, { appearance: "transparent", icon: _jsx(EditRegular, { color: "#512DA8" }), onClick: (e) => handleModalOpen(e, item, "edit") }) })),
        }),
    ];
    const handleModalOpen = (e, policy, type) => {
        e.stopPropagation();
        setModalPolicyData({ [type]: policy });
    };
    const handleModalClose = () => {
        setModalPolicyData({});
    };
    return (_jsxs(_Fragment, { children: [items.length ? (_jsxs(StyledDataGrid, Object.assign({ columns: columns, focusMode: "composite", items: items }, { children: [_jsx(DataGridHeader, { children: _jsx(DataGridRow, { children: ({ renderHeaderCell }) => (_jsx(DataGridHeaderCell, { children: renderHeaderCell() })) }) }), _jsx(DataGridBody, { children: ({ item, rowId }) => (_jsx(DataGridRowWrapper, { children: _jsx(DataGridRow, Object.assign({ style: {
                                    borderBottomStyle: rowId === items.length - 1 ? "none" : "solid",
                                } }, { children: ({ renderCell }) => {
                                    return _jsx(DataGridCell, { children: renderCell(item) });
                                } })) })) })] }))) : (_jsx("span", { children: "No policies were found!" })), _jsx(Dialog, Object.assign({ open: Object.keys(modalPolicyData).length > 0, onOpenChange: handleModalClose }, { children: !!modalPolicyData.edit ? (_jsx(EditPolicyModal, { policySource: policySource, policyItem: modalPolicyData.edit, onClose: handleModalClose, onSave: onSave })) : modalPolicyData.reminder ? (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsx(DialogTitle, { children: "Send reminder" }), _jsx(SendReminderModalBody, { policySource: policySource, policyItem: modalPolicyData.reminder, onClose: handleModalClose })] }) })) : (_jsx(PolicyUserInfoModal, { policySource: policySource, policyItem: modalPolicyData.userInfo, onClose: handleModalClose })) }))] }));
};
export default PolicyItemList;
