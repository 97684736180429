var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from "react";
import { styled } from "styled-components";
import { Button, CompoundButton, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, Link, Tab, TabList, } from "@fluentui/react-components";
import { AlertUrgent16Regular, ArrowLeftRegular, Table16Regular, ArrowLeft16Regular, ArrowRight16Regular, } from "@fluentui/react-icons";
import UserPersona from "../components/UserPersona";
import SendReminderModalBody from "./SendReminderModalBody";
import { PoliciesService } from "../PoliciesService";
import * as XLSX from "xlsx";
const StyledDialogTitle = styled(DialogTitle).withConfig({ displayName: "StyledDialogTitle", componentId: "-xxw1yj" }) `
  align-items: center;
  display: flex;
`;
const StyledDialogContent = styled(DialogContent).withConfig({ displayName: "StyledDialogContent", componentId: "-kuly6z" }) `
  display: flex;
  flex-direction: column;
`;
const CenteredTabListContainer = styled.div.withConfig({ displayName: "CenteredTabListContainer", componentId: "-eo72w8" }) `
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 5px;
`;
const Pagination = styled.div.withConfig({ displayName: "Pagination", componentId: "-1yir1dj" }) `
  display: flex;
  flex-direction: row;
  justify-content: center;
`;
const PaginationButton = styled(CompoundButton).withConfig({ displayName: "PaginationButton", componentId: "-ukdysp" }) `
  margin-right: 10px;
  min-width: 15px;
  min-height: 15px;
  border-radius: 4px;
`;
const PaginationDivider = styled.div.withConfig({ displayName: "PaginationDivider", componentId: "-tuft2u" }) `
  background: transparent;
  width: 15px;
`;
const UserListHeader = styled.div.withConfig({ displayName: "UserListHeader", componentId: "-opbbop" }) `
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;
const UserListLabel = styled.span.withConfig({ displayName: "UserListLabel", componentId: "-1mwk9ri" }) `
  color: "#707070";
  font-weight: 700;
`;
const ActionButtonContainer = styled.div.withConfig({ displayName: "ActionButtonContainer", componentId: "-1ploum8" }) `
  display: flex;
  gap: 12px;
`;
const VerticalDivider = styled.div.withConfig({ displayName: "VerticalDivider", componentId: "-vxxf70" }) `
  background: #cccccc;
  height: 20px;
  width: 1px;
`;
const UserListContainer = styled.div.withConfig({ displayName: "UserListContainer", componentId: "-1jzmh3l" }) `
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 12px;
`;
const StyledDialogActions = styled(DialogActions).withConfig({ displayName: "StyledDialogActions", componentId: "-1cb17vw" }) `
  padding-top: 12px;
`;
const PolicyUserInfoModal = ({ policySource, policyItem, onClose, }) => {
    const maxTargetUsersPerPage = 5;
    const { GetUnconfirmedTargetUsers, GetConfirmedTargetUsers, ExportTargetUsers, } = PoliciesService();
    const [totalPages, setTotalPages] = useState(0);
    const [totalUsers, setTotalUsers] = useState(0);
    const [users, setUsers] = useState([]);
    const [selectedTab, setSelectedTab] = useState("confirmed");
    const [isReminderModalOpen, setIsReminderModalOpen] = useState(false);
    const [page, setPage] = React.useState(1);
    const getConfirmedTargets = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((policyItem === null || policyItem === void 0 ? void 0 : policyItem.id) == undefined || (policySource === null || policySource === void 0 ? void 0 : policySource.id) == undefined)
            return;
        const policyTargets = yield GetConfirmedTargetUsers(policySource === null || policySource === void 0 ? void 0 : policySource.id, policyItem === null || policyItem === void 0 ? void 0 : policyItem.id, page, maxTargetUsersPerPage);
        setTotalUsers(policyTargets.totalDocuments);
        setTotalPages(policyTargets.total);
        setUsers(policyTargets.data);
    });
    const getUnconfirmedTargets = () => __awaiter(void 0, void 0, void 0, function* () {
        if ((policyItem === null || policyItem === void 0 ? void 0 : policyItem.id) == undefined || (policySource === null || policySource === void 0 ? void 0 : policySource.id) == undefined)
            return;
        const policyTargets = yield GetUnconfirmedTargetUsers(policySource.id, policyItem.id, page, maxTargetUsersPerPage);
        setTotalUsers(policyTargets.totalDocuments);
        setTotalPages(policyTargets.totalPages);
        setUsers(policyTargets.data);
    });
    useEffect(() => {
        if (selectedTab == "confirmed")
            getConfirmedTargets();
        else
            getUnconfirmedTargets();
    }, [selectedTab, page]);
    const onDownload = () => __awaiter(void 0, void 0, void 0, function* () {
        var targets = yield ExportTargetUsers(policySource.id, policyItem.id);
        var wb = XLSX.utils.book_new();
        var ws1 = XLSX.utils.json_to_sheet(targets === null || targets === void 0 ? void 0 : targets.Confirmed);
        XLSX.utils.book_append_sheet(wb, ws1, "Confirmed");
        var ws2 = XLSX.utils.json_to_sheet(targets === null || targets === void 0 ? void 0 : targets.Unconfirmed);
        XLSX.utils.book_append_sheet(wb, ws2, "Unconfirmed");
        XLSX.writeFile(wb, `${policySource.name}_${policyItem.title}_TargetUsersExport.xlsx`);
    });
    const handleTabSelect = (_, data) => {
        setSelectedTab(data.value);
        setTotalPages(1);
        setPage(1);
    };
    const handleSendReminderModalOpen = () => {
        setIsReminderModalOpen(true);
    };
    const handleSendReminderModalClose = () => {
        setIsReminderModalOpen(false);
    };
    const decrementPagination = () => {
        if (page - 1 < 1)
            setPage(1);
        else
            setPage(page - 1);
    };
    const incrementPagination = () => {
        if (page + 1 > totalPages)
            setPage(totalPages);
        else
            setPage(page + 1);
    };
    return (_jsx(DialogSurface, { children: _jsxs(DialogBody, { children: [_jsxs(StyledDialogTitle, { children: [isReminderModalOpen ? (_jsx(Button, { appearance: "transparent", icon: _jsx(ArrowLeftRegular, { style: {
                                    height: "20px",
                                    width: "20px",
                                } }), onClick: handleSendReminderModalClose })) : null, isReminderModalOpen
                            ? "Send reminder"
                            : `Check users who read ${policyItem === null || policyItem === void 0 ? void 0 : policyItem.title}`] }), isReminderModalOpen ? (_jsx(SendReminderModalBody, { policySource: policySource, policyItem: policyItem, onClose: handleSendReminderModalClose })) : (_jsxs(_Fragment, { children: [_jsxs(StyledDialogContent, { children: [_jsx(CenteredTabListContainer, { children: _jsxs(TabList, Object.assign({ selectedValue: selectedTab, onTabSelect: handleTabSelect }, { children: [_jsx(Tab, Object.assign({ id: "confirmed", value: "confirmed" }, { children: "Confirmed" })), _jsx(Tab, Object.assign({ id: "unconfirmed", value: "unconfirmed" }, { children: "Unconfirmed" }))] })) }), _jsxs(UserListHeader, { children: [_jsx(UserListLabel, { children: `${selectedTab[0].toUpperCase()}${selectedTab.slice(1)} users (${totalUsers})` }), _jsxs(ActionButtonContainer, { children: [_jsxs(Link, Object.assign({ as: "button", style: {
                                                        alignItems: "center",
                                                        display: "flex",
                                                        gap: "4px",
                                                    }, onClick: onDownload }, { children: [_jsx(Table16Regular, { color: "#512DA8" }), " Download as excel"] })), selectedTab === "unconfirmed" &&
                                                    (policyItem === null || policyItem === void 0 ? void 0 : policyItem.unconfirmedUsers) > 0 ? (_jsxs(_Fragment, { children: [_jsx(VerticalDivider, {}), _jsxs(Link, Object.assign({ as: "button", style: {
                                                                alignItems: "center",
                                                                display: "flex",
                                                                gap: "4px",
                                                            }, onClick: handleSendReminderModalOpen }, { children: [_jsx(AlertUrgent16Regular, { color: "#512DA8" }), " Send reminder"] }))] })) : null] })] }), _jsx(UserListContainer, { children: (users === null || users === void 0 ? void 0 : users.length) > 0
                                        ? users === null || users === void 0 ? void 0 : users.map((user, idx) => (_jsx(UserPersona, { status: selectedTab === "confirmed"
                                                ? "available"
                                                : "do-not-disturb", user: user }, idx)))
                                        : selectedTab == "confirmed"
                                            ? "No users confirmed the policy read yet."
                                            : "Set target audiences to define users who has to confirm the read of this policy." }), totalPages > 1 ? (_jsxs(Pagination, { children: [_jsx(PaginationButton, Object.assign({ onClick: decrementPagination }, { children: _jsx(ArrowLeft16Regular, {}) })), _jsx(PaginationDivider, {}), _jsx(PaginationButton, Object.assign({ onClick: incrementPagination }, { children: _jsx(ArrowRight16Regular, {}) }))] })) : null] }), _jsx(StyledDialogActions, { children: _jsx(Button, Object.assign({ appearance: "primary", onClick: onClose }, { children: "OK" })) })] }))] }) }));
};
export default PolicyUserInfoModal;
