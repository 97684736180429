var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import styled from "styled-components";
import { RiCheckboxCircleLine, RiImage2Line } from "react-icons/ri";
import { useBranding } from "./BrandingContext";
import uploadIcon from "/public/logoUpload.svg";
import uploadIconActive from "/public/logoUploadActive.svg";
import HeaderPreview from "./components/HeaderPreview";
import ColorThief from "colorthief";
import a11yContrastChecker from "../components/constrastChecker";
import NavigationComponent from "./NavigationComponent";
import BrandingHeader from "./components/brandingHeader";
const Container = styled.div.withConfig({ displayName: "Container", componentId: "-5675h3" }) `
  display: flex;
  flex-direction: column;

  &.mode-wizard {
    min-height: calc(100vh - 200px);
  }
`;
const UiContainer = styled.div.withConfig({ displayName: "UiContainer", componentId: "-qgcgp0" }) `
  display: flex;
  flex-direction: column;
  margin-block: auto;
  gap: 20px;
  width: 100%;

  > div {
    min-width: 305px;
  }

  h3 {
    color: #3d3a3b;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 20px;
  }

  #logoCheckCanvas {
    display: none;
  }
`;
const LogoUIContianer = styled.div.withConfig({ displayName: "LogoUIContianer", componentId: "-171wxmx" }) `
  display: flex;
  margin: 0 25%;
`;
const LogoUploadContianer = styled.div.withConfig({ displayName: "LogoUploadContianer", componentId: "-155jvo3" }) `
  flex: 1 1 65%;
`;
const LogoUpload = styled.div.withConfig({ displayName: "LogoUpload", componentId: "-1yjrl7c" }) `
  background: rgba(175, 173, 178, 0.04);
  border: 1px dashed rgba(81, 45, 168, 0.2);
  border-radius: 10px;
  padding: 20px 60px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #3d3a3b;
  font-weight: 600;
  font-size: 16px;
  transition: all 0.2s ease-in-out;

  .upload-icon-active {
    display: none;
  }

  &.drag-over {
    border-color: rgba(81, 45, 168, 1);

    .upload-icon {
      display: none;
    }

    .upload-icon-active {
      display: block;
    }
  }

  input {
    display: none;
  }

  p {
    margin-block: 0 10px;
    font-size: 14px;
    font-weight: 600;
    text-align: center;

    + p {
      font-size: 12px;
      font-weight: 400;
    }
  }

  span {
    cursor: pointer;
    color: #512da8;
  }
  ${(props) => props.tryToSave &&
    !props.hasLogo &&
    `
  border: 1px dashed red;
`}
`;
const UploadChecks = styled.div.withConfig({ displayName: "UploadChecks", componentId: "-19q4g6l" }) `
  flex: 1 1 35%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  margin-bottom: 60px;
`;
const UploadCheck = styled.div.withConfig({ displayName: "UploadCheck", componentId: "-sfpqpp" }) `
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-block: 6px;
  padding-left: 22px;
  color: #707070;

  svg {
    position: absolute;
    top: 10px;
    left: 0;
  }

  &.success {
    color: #107c10;
  }

  &.warning {
    color: #a4262c;
  }
`;
const LogoUploadIcon = styled.img.withConfig({ displayName: "LogoUploadIcon", componentId: "-1wy5iac" }) ``;
const LogoInfoContainer = styled.div.withConfig({ displayName: "LogoInfoContainer", componentId: "-1dkb0wb" }) `
  height: 32px;
  margin-block: 12px;
`;
const LogoInfo = styled.div.withConfig({ displayName: "LogoInfo", componentId: "-5q5rf4" }) `
  display: flex;
  align-items: center;
  background: rgba(81, 45, 168, 0.05);
  border-radius: 6px;
  padding: 7px 15px;
  font-size: 12px;

  svg {
    font-size: 18px;
  }
`;
const LogoInfoName = styled.span.withConfig({ displayName: "LogoInfoName", componentId: "-ar17a" }) `
  font-weight: 600;
  margin-inline: 8px;
`;
const LogoInfoSize = styled.span.withConfig({ displayName: "LogoInfoSize", componentId: "-yokhn3" }) ``;
const DismissLogoIcon = styled.span.withConfig({ displayName: "DismissLogoIcon", componentId: "-m9aj8h" }) `
  margin-left: auto;
  cursor: pointer;
`;
const ColorPreviewDisc = styled.div.withConfig({ displayName: "ColorPreviewDisc", componentId: "-oobg6r" }) `
  display: inline-block;
  height: 20px;
  width: 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  margin-inline: 6px;
`;
const Logo = (props) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1;
    const { selectedTheme, setSelectedTheme } = useBranding();
    const [hasLogo, setHasLogo] = React.useState(false);
    const [logoChecks, setLogoChecks] = React.useState(((_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _a === void 0 ? void 0 : _a.checks) ? selectedTheme.logo.checks : null);
    const logoInputRef = React.useRef(null);
    React.useEffect(() => {
        var _a;
        setHasLogo(!!((_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _a === void 0 ? void 0 : _a.base64));
    }, [selectedTheme]);
    React.useEffect(() => {
        if (selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) {
            setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { logo: Object.assign(Object.assign({}, prev.logo), { checks: logoChecks }) })));
        }
    }, [logoChecks]);
    const checkLogo = (file) => {
        const colorThief = new ColorThief();
        let hasAlpha = false;
        setLogoChecks((prev) => (Object.assign(Object.assign({}, prev), { max16MB: file.size < 16 * 1024 * 1024 })));
        return new Promise((resolve, reject) => {
            const canvas = document.querySelector("canvas");
            const ctx = canvas.getContext("2d");
            const img = new Image();
            img.src = URL.createObjectURL(file);
            img.crossOrigin = "Anonymous";
            img.onerror = reject;
            img.onload = function () {
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                const imgData = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
                for (let j = 0; j < imgData.length; j += 4) {
                    if (imgData[j + 3] < 255) {
                        hasAlpha = true;
                        break;
                    }
                }
                const primaryLogoColor = rgbToHex(colorThief.getColor(img)[0], colorThief.getColor(img)[1], colorThief.getColor(img)[2]);
                setLogoChecks((prev) => {
                    var _a, _b;
                    return (Object.assign(Object.assign({}, prev), { primaryLogoColor: primaryLogoColor, highContrast: {
                            intense: a11yContrastChecker(primaryLogoColor, ((_b = (_a = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.primaryColor)
                                ? selectedTheme.themeSettings.color.primaryColor
                                : "#3D3A3B").levels.aaNormal,
                            soft: a11yContrastChecker(primaryLogoColor, "#f7f7f7").levels
                                .aaNormal,
                        }, isPNG: file.type === "image/png", transparentBackground: hasAlpha }));
                });
            };
        });
    };
    const rgbToHex = (r, g, b) => "#" +
        [r, g, b]
            .map((x) => {
            const hex = x.toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        })
            .join("");
    const getLogoData = (file) => {
        if (!file)
            return selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo;
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve({
                    name: file.name,
                    type: file.type,
                    size: `${Math.round(file.size / 1000)}kB`,
                    base64: fileReader.result,
                });
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
            checkLogo(file);
        });
    };
    const handleDragOver = (event) => {
        event.preventDefault();
        event.currentTarget.classList.add("drag-over");
    };
    const handleDragLeave = (event) => {
        event.preventDefault();
        event.currentTarget.classList.remove("drag-over");
    };
    const handleDrop = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        event.currentTarget.classList.contains("drag-over") &&
            event.currentTarget.classList.remove("drag-over");
        const droppedFiles = Array.from(event.dataTransfer.files);
        if (droppedFiles.some((file) => !file.type.startsWith("image/")))
            return;
        const logoData = yield getLogoData(droppedFiles[0]);
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { logo: logoData })));
    });
    const handleSelected = (event) => __awaiter(void 0, void 0, void 0, function* () {
        event.preventDefault();
        const droppedFiles = Array.from(event.target.files);
        if (droppedFiles.length > 0) {
            const logoData = yield getLogoData(droppedFiles[0]);
            setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { logo: logoData })));
            event.target.value = null;
        }
    });
    const inputUpload = () => {
        logoInputRef.current.click();
    };
    const dismissLogo = () => {
        setSelectedTheme((prev) => (Object.assign(Object.assign({}, prev), { logo: Object.assign(Object.assign({}, prev.logo), { name: "", type: "", size: "", base64: "", checks: null }) })));
    };
    return (_jsxs(Container, Object.assign({ className: props.wizardMode ? "mode-wizard" : "mode-edit" }, { children: [props.wizardMode ? (_jsx(BrandingHeader, { title: "Upload the company logo", summary: "Upload the company logo be applied on the MegaMenu.", advancedSettings: true })) : null, _jsxs(UiContainer, { children: [_jsxs(LogoUIContianer, { children: [_jsxs(LogoUploadContianer, { children: [_jsxs(LogoUpload, Object.assign({ onDragOver: handleDragOver, onDragLeave: handleDragLeave, onDrop: handleDrop, tryToSave: props === null || props === void 0 ? void 0 : props.tryToSave, hasLogo: hasLogo }, { children: [_jsx(LogoUploadIcon, { className: "upload-icon", src: uploadIcon }), _jsx(LogoUploadIcon, { className: "upload-icon-active", src: uploadIconActive }), _jsxs("p", { children: ["Drag and drop the file or", " ", _jsx("span", Object.assign({ onClick: inputUpload }, { children: "upload from desktop" })), "."] }), _jsx("p", { children: "file format only" }), _jsx("input", { type: "file", ref: logoInputRef, onChange: (e) => handleSelected(e), accept: "image/*" })] })), _jsx(LogoInfoContainer, { children: ((_b = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _b === void 0 ? void 0 : _b.name) ? (_jsxs(LogoInfo, { children: [_jsx(RiImage2Line, {}), _jsx(LogoInfoName, { children: selectedTheme.logo.name }), _jsx(LogoInfoSize, { children: selectedTheme.logo.size }), _jsx(DismissLogoIcon, Object.assign({ onClick: dismissLogo }, { children: "X" }))] })) : null })] }), _jsxs(UploadChecks, { children: [(selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) ? (_jsx(_Fragment, { children: (((_e = (_d = (_c = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _c === void 0 ? void 0 : _c.checks) === null || _d === void 0 ? void 0 : _d.highContrast) === null || _e === void 0 ? void 0 : _e.intense) &&
                                            ((_g = (_f = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _f === void 0 ? void 0 : _f.color) === null || _g === void 0 ? void 0 : _g.style) === "intense") ||
                                            (((_k = (_j = (_h = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _h === void 0 ? void 0 : _h.checks) === null || _j === void 0 ? void 0 : _j.highContrast) === null || _k === void 0 ? void 0 : _k.soft) &&
                                                ((_m = (_l = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.themeSettings) === null || _l === void 0 ? void 0 : _l.color) === null || _m === void 0 ? void 0 : _m.style) === "soft") ? (_jsxs(UploadCheck, Object.assign({ className: "success" }, { children: [_jsx(RiCheckboxCircleLine, {}), " High contrast"] }))) : (_jsxs(UploadCheck, Object.assign({ className: ((_p = (_o = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _o === void 0 ? void 0 : _o.checks) === null || _p === void 0 ? void 0 : _p.highContrast) ? "warning" : "" }, { children: [_jsx(RiCheckboxCircleLine, {}), " Insert a logo that creates higher contrast with the menu background"] }))) })) : (_jsxs(UploadCheck, { children: [_jsx(RiCheckboxCircleLine, {}), " High contrast"] })), _jsxs(UploadCheck, Object.assign({ className: ((_r = (_q = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _q === void 0 ? void 0 : _q.checks) === null || _r === void 0 ? void 0 : _r.isPNG)
                                            ? "success"
                                            : ((_t = (_s = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _s === void 0 ? void 0 : _s.checks) === null || _t === void 0 ? void 0 : _t.isPNG) === false
                                                ? "warning"
                                                : "" }, { children: [_jsx(RiCheckboxCircleLine, {}), " .PNG file"] })), _jsxs(UploadCheck, Object.assign({ className: ((_v = (_u = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _u === void 0 ? void 0 : _u.checks) === null || _v === void 0 ? void 0 : _v.transparentBackground)
                                            ? "success"
                                            : ((_x = (_w = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _w === void 0 ? void 0 : _w.checks) === null || _x === void 0 ? void 0 : _x.transparentBackground) === false
                                                ? "warning"
                                                : "" }, { children: [_jsx(RiCheckboxCircleLine, {}), " Transparent background"] })), _jsxs(UploadCheck, Object.assign({ className: ((_z = (_y = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _y === void 0 ? void 0 : _y.checks) === null || _z === void 0 ? void 0 : _z.max16MB)
                                            ? "success"
                                            : ((_1 = (_0 = selectedTheme === null || selectedTheme === void 0 ? void 0 : selectedTheme.logo) === null || _0 === void 0 ? void 0 : _0.checks) === null || _1 === void 0 ? void 0 : _1.max16MB) === false
                                                ? "warning"
                                                : "" }, { children: [_jsx(RiCheckboxCircleLine, {}), " 16 MB max"] }))] })] }), _jsx(HeaderPreview, {}), _jsx("canvas", { id: "logoCheckCanvas", width: "240", height: "340" })] }), props.wizardMode ? (_jsx(NavigationComponent, { currentStepName: "Logo", hasLogo: hasLogo })) : null] })));
};
export default Logo;
