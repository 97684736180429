var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from "react";
import styled from "styled-components";
import { Button, DataGrid, DataGridBody, DataGridCell, DataGridHeader, DataGridHeaderCell, DataGridRow, Dialog, TableCellLayout, createTableColumn, Tooltip, Switch, Spinner } from "@fluentui/react-components";
import { AddSquareMultipleRegular, DeleteRegular, EditRegular, ReorderRegular, } from "@fluentui/react-icons";
import { Draggable } from "react-beautiful-dnd";
import EditQuicklinkModal from "./modals/EditQuicklinkModal";
import envVarConfig from "../../../env/envVarConfig";
import { DefaultIcon } from "./DefaultIcon";
import { QuicklinksService } from "./QuicklinksService";
const forFebruary = false;
const StyledDataGrid = styled(DataGrid).withConfig({ displayName: "StyledDataGrid", componentId: "-n1xjph" }) `
  padding-left: ${forFebruary ? "64px" : "0px"};
`;
const DraggableInnerContainer = styled.div.withConfig({ displayName: "DraggableInnerContainer", componentId: "-1rn7xce" }) `
  position: relative;
`;
const DragHandleButton = styled(Button).withConfig({ displayName: "DragHandleButton", componentId: "-a2qpbf" }) `
  bottom: 0;
  left: -48px;
  position: absolute;
  top: 0;
`;
const DraggingContainer = styled.div.withConfig({ displayName: "DraggingContainer", componentId: "-cg7i49" }) `
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: ${(props) => props.$isStacked
    ? `0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13),
    0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.1)`
    : undefined};
  height: ${(props) => (props.$isStacked ? "48px" : "44px")};
  left: ${(props) => (props.$isStacked ? 0 : undefined)};
  position: ${(props) => (props.$isStacked ? "absolute" : "relative")};
  top: ${(props) => (props.$isStacked ? 0 : undefined)};
  width: ${(props) => (props.$isStacked ? "304px" : "300px")};
`;
const DataGridRowWrapper = styled.div.withConfig({ displayName: "DataGridRowWrapper", componentId: "-1r2i1z0" }) `
  position: relative;
  width: 100%;
`;
const DraggingLabel = styled.span.withConfig({ displayName: "DraggingLabel", componentId: "-4btmkr" }) `
  display: inline-block;
  border-radius: 8px;
  box-shadow: 0px 1.6px 3.6px 0px rgba(0, 0, 0, 0.13),
    0px 0.3px 0.9px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  padding: 12px 24px;
  position: relative;
  width: 100%;
  z-index: 2;
`;
const SelectionNrIndicator = styled.div.withConfig({ displayName: "SelectionNrIndicator", componentId: "-lhvb5q" }) `
  align-items: center;
  background-color: #512da8;
  border-radius: 50%;
  color: #ffffff;
  display: flex;
  font-size: 14px;
  font-weight: 700;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -8px;
  text-align: center;
  top: -8px;
  width: 24px;
  z-index: 2;
`;
const AudiencesTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "AudiencesTableCellLayout", componentId: "-tduo8c" }) `
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & > div {
    overflow: hidden;
  }
`;
const TruncatedSpan = styled.span.withConfig({ displayName: "TruncatedSpan", componentId: "-8tz6ax" }) `
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
const ActionsTableCellLayout = styled(TableCellLayout).withConfig({ displayName: "ActionsTableCellLayout", componentId: "-1m3xk26" }) `
  justify-content: flex-end;
`;
const SelectedLinkIndicator = styled.div.withConfig({ displayName: "SelectedLinkIndicator", componentId: "-vxhd14" }) `
  background-color: rgba(247, 247, 247, 0.5);
  inset: 0;
  position: absolute;
`;
const QuicklinkCategoryList = ({ audiences, category, isDraggingLinkSelected, selectedLinksNr, selectedLinks = [], onQuicklinksDelete, onSelectedLinksChange, refresh, }) => {
    const [linkToEdit, setLinkToEdit] = useState();
    const { id, quicklinks } = category;
    const [iconsWithError, setIconWithError] = React.useState([]);
    const handleImageError = (id) => {
        setIconWithError([...iconsWithError, id]);
    };
    const handleAudiences = (item) => {
        var _a;
        const linkAudiences = audiences.filter((aud) => item.audiences.includes(aud.audienceId)).map(aud => aud);
        return !!linkAudiences && linkAudiences.length > 0
            ? (_a = linkAudiences.map((aud) => aud === null || aud === void 0 ? void 0 : aud.audienceName)) === null || _a === void 0 ? void 0 : _a.join(", ")
            : "—";
    };
    const showEnabledEverywhereColumn = envVarConfig.environment === "local" ||
        envVarConfig.environment === "dev" ||
        envVarConfig.environment === "test";
    const columns = [
        createTableColumn({
            columnId: "name",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("span", Object.assign({ style: { color: "#3D3A3B", fontWeight: "600" } }, { children: "Name" })) })),
            renderCell: (item) => (_jsxs(_Fragment, { children: [!iconsWithError.includes(item.icon.iconId) ?
                        (_jsx("img", { src: (item.icon.type == "External"
                                ? ""
                                : envVarConfig.reactApiUrl) + item.icon.url, style: { width: "16px", height: "16px", marginRight: "5px" }, alt: "Icon", onError: handleImageError.bind(this, item.icon.iconId) }))
                        :
                            (_jsx(DefaultIcon, {})), _jsx(Tooltip, Object.assign({ appearance: "inverted", content: item.name, positioning: "after-bottom", relationship: "description", withArrow: true }, { children: _jsx(TruncatedSpan, { children: item.name }) }))] })),
        }),
        createTableColumn({
            columnId: "url",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("span", Object.assign({ style: { color: "#3D3A3B", fontWeight: "600" } }, { children: "URL" })) })),
            renderCell: (item) => (_jsx(Tooltip, Object.assign({ appearance: "inverted", content: item.url, positioning: "after-bottom", relationship: "description", withArrow: true }, { children: _jsx(TruncatedSpan, { children: item.url }) }))),
        }),
        createTableColumn({
            columnId: "audiences",
            renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("span", Object.assign({ style: { color: "#3D3A3B", fontWeight: "600" } }, { children: "Audiences" })) })),
            renderCell: (item) => (_jsx(AudiencesTableCellLayout, { children: _jsx(TruncatedSpan, { children: handleAudiences(item) }) })),
        }),
        ...(showEnabledEverywhereColumn
            ? [
                createTableColumn({
                    columnId: "enabledEverywhere",
                    renderHeaderCell: () => (_jsx(TableCellLayout, { children: _jsx("span", Object.assign({ style: { color: "#3D3A3B", fontWeight: "600" } }, { children: "Shown In Everywhere Widget" })) })),
                    renderCell: (item) => (_jsx(Tooltip, Object.assign({ appearance: "inverted", content: item.url, positioning: "after-bottom", relationship: "description", withArrow: true }, { children: _jsx(TruncatedSpan, { children: isSaving ? (_jsx(Spinner, { size: "small", label: "Saving..." })) : (_jsx(Switch, { checked: item.showOnEverywhereWidget, onClick: (e) => useShowOnEverywhereChanged(e, item) })) }) }))),
                }),
            ]
            : []),
        createTableColumn({
            columnId: "actions",
            renderHeaderCell: () => "",
            renderCell: (item) => (_jsxs(ActionsTableCellLayout, { children: [forFebruary && _jsx(Button, { appearance: "transparent", icon: _jsx(AddSquareMultipleRegular, { color: "#512DA8" }), onClick: handleLinkCopyClick }), _jsx(Button, { appearance: "transparent", icon: _jsx(EditRegular, { color: "#512DA8" }), onClick: (e) => handleLinkEditClick(e, item) }), _jsx(Button, { appearance: "transparent", icon: _jsx(DeleteRegular, { color: "#512DA8" }), onClick: (e) => onQuicklinksDelete([item], e) })] })),
        }),
    ];
    const dataGridSelectedItems = selectedLinks.map((selectedLink) => {
        return quicklinks.findIndex((link) => link.id === selectedLink.id);
    });
    const handleSelectedLinksChange = (_, data) => {
        const newSelectedLinks = [...data.selectedItems].map((linkIdx) => quicklinks[linkIdx]);
        onSelectedLinksChange(id, newSelectedLinks);
    };
    const handleLinkCopyClick = (e) => {
        // TODO
        e.stopPropagation();
        console.log("TODO Copy clicked");
    };
    const handleLinkEditClick = (e, quicklink) => {
        e.stopPropagation();
        setLinkToEdit(quicklink);
    };
    const useShowOnEverywhereChanged = (e, quicklink) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        e.stopPropagation();
        try {
            setIsSaving(true);
            quicklink.showOnEverywhereWidget = (_a = !quicklink.showOnEverywhereWidget) !== null && _a !== void 0 ? _a : true;
            yield QuicklinksService().EditLink(quicklink);
            setIsSaving(false);
        }
        catch (e) {
            console.error(e);
            setIsSaving(false);
        }
    });
    const [isSaving, setIsSaving] = React.useState(false);
    const handleEditQuicklinkModalClose = () => {
        setLinkToEdit(undefined);
    };
    return (_jsxs(_Fragment, { children: [(quicklinks === null || quicklinks === void 0 ? void 0 : quicklinks.length) ? (_jsxs(StyledDataGrid, Object.assign({ columns: columns, focusMode: "composite", items: quicklinks, selectedItems: forFebruary ? new Set(dataGridSelectedItems) : undefined, selectionMode: forFebruary ? "multiselect" : undefined, onSelectionChange: forFebruary ? handleSelectedLinksChange : undefined }, { children: [_jsx(DataGridHeader, { children: _jsx(DataGridRow, Object.assign({ style: { height: "44px" } }, { children: ({ renderHeaderCell }) => (_jsx(DataGridHeaderCell, { children: renderHeaderCell() })) })) }), _jsx(DataGridBody, Object.assign({ style: { paddingBottom: "20px" } }, { children: ({ item, rowId }) => (_jsx(Draggable, Object.assign({ draggableId: item.id, index: Number(rowId) }, { children: (dragProvided, dragSnapshot) => (_jsx("div", Object.assign({ ref: dragProvided.innerRef }, dragProvided.draggableProps, { children: _jsxs(DraggableInnerContainer, { children: [forFebruary && _jsx(DragHandleButton, Object.assign({ appearance: "transparent", icon: _jsx(ReorderRegular, { color: "#512DA8!important" }) }, dragProvided.dragHandleProps)), dragSnapshot.isDragging && selectedLinks.length ? (_jsxs(DraggingContainer, { children: [_jsx(DraggingLabel, { children: item.name }), _jsx(SelectionNrIndicator, { children: selectedLinksNr }), selectedLinksNr > 1 ? (_jsx(DraggingContainer, { "$isStacked": true })) : null] })) : (_jsxs(DataGridRowWrapper, { children: [_jsx(DataGridRow, Object.assign({ style: {
                                                        borderBottomStyle: rowId === (quicklinks === null || quicklinks === void 0 ? void 0 : quicklinks.length) - 1
                                                            ? "none"
                                                            : "solid",
                                                    } }, { children: ({ renderCell }) => {
                                                        return (_jsx(DataGridCell, { children: renderCell(item) }));
                                                    } })), isDraggingLinkSelected &&
                                                    selectedLinks.some((link) => link.id === item.id) ? (_jsx(SelectedLinkIndicator, {})) : null] }))] }) }), rowId)) }), item.id)) }))] }))) : (_jsx("span", { children: "This category is empty!" })), _jsx(Dialog, Object.assign({ open: !!linkToEdit, onOpenChange: handleEditQuicklinkModalClose }, { children: _jsx(EditQuicklinkModal, { link: linkToEdit, onClose: handleEditQuicklinkModalClose, refresh: refresh }) }))] }));
};
export default QuicklinkCategoryList;
